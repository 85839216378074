import Sorteo from './Sorteo';
import './App.css';
import video from './elements/video.mp4'; 

function App() {
  return (

    <div className='App-header'>
      <video autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </video>
      <Sorteo />
    </div>
  );
}

export default App;
