import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzXiTP6TFkBveHPRT0n2fLzJpUSjQNL3M",
  authDomain: "peasy-8d3fd.firebaseapp.com",
  projectId: "peasy-8d3fd",
  storageBucket: "peasy-8d3fd.appspot.com",
  messagingSenderId: "662130949567",
  appId: "1:662130949567:web:06ec071a741e91d678350a",
  measurementId: "G-CZ9VVQD9QT"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getDatabase(app);
