import React, { useState, useEffect } from "react";
import "./Sorteo.css"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Fireworks } from '@fireworks-js/react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ref, onValue } from "firebase/database";
import { database } from "./firebase";


function Sorteo() {

  const [ganador, setGanador] = useState([]);
  const [animacion, setAnimacion] = useState(false);
  const [nombreAleatorio, setNombreAleatorio] = useState(null);
  const db = database;
  const [data, setData] = useState([]);
  const [nombres, setNombres] = useState([]);
  const [infoSorteo, setInfoSorteo] = useState({});
  const [publicidad, setPublicidad] = useState([]);

  const[flag, setFlag] = useState(true);
  const [segundos, setSegundos] = useState();


  const confImg = 'https://drive.google.com/uc?export=view&id='
  const [currentSlide, setCurrentSlide] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setSegundos((prevSegundos) => prevSegundos - 1);
    }, 1000);
    setIntervalId(id);
    return () => {
      clearInterval(id);
    };
  }, []);

  function formatTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = secs % 60;


    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }

  useEffect(() => {
    if (segundos <= 0) {
      setSegundos(0);
      clearInterval(intervalId);
    }
  }, [segundos, intervalId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(currentSlide === publicidad.length - 1 ? 0 : currentSlide + 1);
    }, 4000);
    return () => clearInterval(interval);
  }, [currentSlide, publicidad.length]);

  useEffect(() => {
    onValue(ref(db, 'SistemaAleatorio/configuracionKoikoi'), (snapshot) => {
      const dataFromSnapshot = snapshot.val();
      setSegundos(dataFromSnapshot.config.tiempo * 60);
      setInfoSorteo({
        qr: dataFromSnapshot.config.qr,
        sorteo: dataFromSnapshot.config.sorteo,
        titulo: dataFromSnapshot.config.titulo,
        logo: dataFromSnapshot.config.logo,
        tiempoParticipantes: dataFromSnapshot.config.tiempoParticipantes
        
      })
      const publicidad = dataFromSnapshot.publicidad.map((pub) => ({
        url: pub.url
      }))
      setPublicidad(publicidad);
    });

    onValue(ref(db, 'SistemaAleatorio/koikoi'), (snapshot) => {
      const dataFromSnapshot = snapshot.val();
      const currentTime = new Date().getTime(); // Obtiene el timestamp actual en milisegundos
      const newData = Object.values(dataFromSnapshot).flatMap((objeto) => {
        return objeto.map((val) => {
          const timestamp = val.date;
          const differenceInMinutes = (currentTime - (timestamp * 1000)) / (1000 * 60); // Calcula la diferencia en minutos
          if (differenceInMinutes > infoSorteo.tiempoParticipantes) { // Compara la diferencia con 30 minutos
            return null; // Si la diferencia es mayor a 30 minutos, retorna null para no incluir el dato en newData
          }
          return {
            nombre: val.contact.variables.nombreParticipante,
            foto: val.contact.photo,
            fecha: val.date // Convierte el timestamp en una fecha legible por humanos
          };
        }).filter((val) => val !== null); // Filtra los valores nulos
      });
      setData(newData);
    });

  }, [db, infoSorteo.tiempoParticipantes])

  
  useEffect(() => {
    const nombresParticipantes = data.map((participante) => ({
      nombre: participante.nombre,
      foto: participante.foto
    }));
    setNombres(nombresParticipantes);
  }, [data])

  useEffect(() => {

    let nombresInterval = null;
    if (animacion) {
      nombresInterval = setInterval(() => {
        const aleatorio = Math.floor(Math.random() * nombres.length);
        setNombreAleatorio(nombres[aleatorio].nombre);
      }, 50);
    } else {
      clearInterval(nombresInterval);
      setNombreAleatorio(null);
    }
    return () => clearInterval(nombresInterval);

  }, [animacion, nombres]);

  const handleSorteo = () => {
    setAnimacion(true);
    setFlag(false);

   
      setTimeout(() => {
        const ganadorIndex = Math.floor(Math.random() * nombres.length);
        setGanador({nombre: nombres[ganadorIndex].nombre, foto: nombres[ganadorIndex].foto } );
        setAnimacion(false);
      }, 10000);
    
    
  };

  return (

    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item
          alignItems="center"
          justify="center" xs={12}>
          <div className="titulo">{infoSorteo.titulo}</div>
        </Grid>
        <Grid item xs={3} >
          <div className="grid-container">
            <div className="subtit">Numero de participantes</div>
            <div className="contNumParticipantes">{data.length}</div>

            <Grid container spacing={2}>
              <Grid item xs={12} >

               <div className="subtit">
                 Participantes
               </div>
                  
                
                <List>
                  {nombres.slice(Math.max(nombres.length - 8, 0)).reverse().map((participante, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <img className="foto" src={participante.foto} alt={participante.nombre} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={participante.nombre}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>


          </div>



        </Grid>
        <Grid item xs={6}>
          <div className="grid-container grid-center">
            <div className="info-sorteo">{infoSorteo.sorteo}</div>

            {flag ? (
                segundos > 0 ? (
                  <div className="tiempo">
                    {formatTime(segundos)}
                  </div>
                ) : (
                  <div className="tiempo">
                    00:00
                  </div>
                )
              ) : null}
            
            {ganador.nombre && (
              <div className="sorteo-winner">
                <h2 className="sorteo-winner-title">¡Felicitaciones!</h2>
                <p className="sorteo-winner-name">{ganador.nombre}</p>
                <img className="foto-ganador" src={ganador.foto}></img>
              </div>

            )}
            {animacion && nombreAleatorio && (
              <div className="sorteo-animation">
                <h2 className="sorteo-animation-title">¡Buena suerte!</h2>
                <p className="sorteo-animation-name">{nombreAleatorio}</p>
              </div>
            )}

              <Button className="boton-comenzar" variant="outlined" onClick={handleSorteo}>
              Comenzar
            </Button>
          
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="grid-container" >

            <div className="titulo-qr">¡Escanéame y participa!</div>
            <div className="qr"><img src={confImg + infoSorteo.qr} /></div>
            <div className="info">

              <div className="logo">
              <img src={confImg + infoSorteo.logo}/>
              </div>

            </div>

          </div>
        </Grid>

        <Grid item
          alignItems="center"
          justify="center" xs={12}>


          <div className="publicidad">
            {publicidad.map((item, index) => (
              <img
                key={index}
                src={confImg + item.url}
                alt={`Slide ${index}`}
                style={{ display: index === currentSlide ? 'block' : 'none' }}
              />
            ))}
          </div>


        </Grid>
      </Grid>

      {ganador.nombre && (
        <Fireworks
          style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
          }}
        />
      )}

    </Container>

  );
}

export default Sorteo;